(function ( $ ) {
     $.fn.scrollMenu = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink   = $(this);
        var didScroll = false;

        function _init() {

            objLink.each(function (index) {
                _setScroll($(this));
            });

            $(window).scroll(function(event){
                  didScroll = true;
             });
        }

        function _setScroll(o){
            setInterval(function() {
                 if (didScroll) {
                     hasScrolled($(window));
                     didScroll = false;
                 }
             }, 250);
        }

        function hasScrolled(o){
            scrollTop = $(document).scrollTop().valueOf();
            if(scrollTop > 0){
                objLink.addClass('hasScrolled');
            }else{
                objLink.removeClass('hasScrolled');
            }
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$('#home-head').scrollMenu();
